import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeCleanerIcon from "./Icons/OrangeCleanerIcon.jsx";
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import OrangeBlueSky from "./Icons/OrangeBlueSky.jsx";
import CurtainEffect from "./CurtainEffect.js";
import BathroomIcon from "./Icons/hero-icons/BathroomIcon.jsx";
import BedIcon from "./Icons/hero-icons/BedIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";

//Images
import HomeStagingPage1 from "./Images/HomeStagingPage1.png";
import HomeStagingPage2 from "./Images/HomeStagingPage2.png";
import HomeStagingPage3 from "./Images/HomeStagingPage3.png";
import HomeStagingPage4 from "./Images/HomeStagingPage4.png";
import HomeStagingPage5 from "./Images/HomeStagingPage5.png";
import HomeStagingPage6 from "./Images/HomeStagingPage6.png";
import HomeStagingPage7 from "./Images/HomeStagingPage7.png";
import RealEstatePhotographyEsPage6 from "./Images/RealEstatePhotographyEsPage6.jpeg";
import Bouchra from "./Images/Bouchra image.png";
import DavidCanoyra from "./Images/David Canoyra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import MacarenaRico from "./Images/Macarena Rico image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeBedroomStaging from "./Images/curtain-effect-hero/BeforeBedroomStaging.png";
import AfterBedroomStaging from "./Images/curtain-effect-hero/AfterBedroomStaging.png";
import BeforeTerraceStaging from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterTerraceStaging from "./Images/curtain-effect-hero/AfterTerraceStaging.png";
import BeforeBathroomStaging from "./Images/curtain-effect-hero/BeforeBathroomStaging.png";
import AfterBathroomStaging from "./Images/curtain-effect-hero/AfterBathroomStaging.png";

function redirectToPage() {
  if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

const structuredData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "Qu'est-ce que le home staging ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Le home staging est une technique de marketing immobilier qui consiste à préparer un bien (maison, appartement, etc.) pour la vente ou la location dans le but de le rendre plus attrayant pour les acheteurs ou locataires potentiels. Cela se fait par une présentation et une décoration stratégiques de l'espace pour mettre en valeur ses caractéristiques les plus positives et minimiser ses défauts.",
      },
    },
    {
      "@type": "Question",
      name: "Que comprend le home staging virtuel ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Le home staging virtuel utilise des outils numériques pour présenter un bien de manière attrayante. Il comprend l'édition d'images avec l'IA pour retoucher et améliorer les photographies, la création de rendus de conception pour visualiser les rénovations potentielles, et offre une économie de temps et d'argent par rapport au home staging traditionnel. Il permet également une grande flexibilité et personnalisation dans la présentation du bien.",
      },
    },
    {
      "@type": "Question",
      name: "Quelles sont les étapes du processus de Home Staging Virtuel ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Le processus de home staging virtuel avec Pedra comprend trois étapes principales : 1. Télécharger les photos du projet. 2. Utiliser les options de Home Staging Virtuel dans le menu 'Éditer avec l'IA', qui incluent le vidage des pièces, l'ajout de meubles, la rénovation des espaces, etc. 3. Télécharger les images finales du Home Staging une fois toutes les modifications effectuées.",
      },
    },
    {
      "@type": "Question",
      name: "Peut-on réaliser un Home Staging Virtuel sur des propriétés vides ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Oui ! Notre outil de home staging virtuel permet de meubler les espaces vides. Vous pouvez utiliser l'option 'Meubler' pour ajouter automatiquement des meubles avec l'IA, ou ajouter manuellement des meubles avec l'option 'Ajouter un objet'. Ces fonctionnalités vous aident à visualiser le potentiel des espaces, facilitant la prise de décision et améliorant la présentation de la propriété.",
      },
    },
    {
      "@type": "Question",
      name: "Quel est le prix du home staging virtuel ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Le prix de Pedra est de 29 € plus TVA par mois. Avec ce paiement, vous pouvez générer jusqu'à 100 images par mois. Il n'y a pas d'engagement, vous pouvez donc annuler lorsque vous n'en avez plus besoin.",
      },
    },
  ],
};

function HomeStageVirtuelFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);

  const [example, setExample] = useState({
    name: "Furnish",
    beforeImage: BeforeFurnish,
    afterImage: AfterFurnish,
  });

  const handleExample = (example) => {
    console.log(example);
    if (example === "Furnish") {
      setExample({
        name: "Furnish",
        beforeImage: BeforeFurnish,
        afterImage: AfterFurnish,
      });
      console.log("Furnish");
    } else if (example === "Bedroom") {
      setExample({
        name: "Bedroom",
        beforeImage: BeforeBedroomStaging,
        afterImage: AfterBedroomStaging,
      });
    } else if (example === "Terrace") {
      setExample({
        name: "Terrace",
        beforeImage: BeforeTerraceStaging,
        afterImage: AfterTerraceStaging,
      });
    } else {
      setExample({
        name: "Bathroom",
        beforeImage: BeforeBathroomStaging,
        afterImage: AfterBathroomStaging,
      });
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home Staging Virtuel</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2
                  style={{
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  Le meilleur home staging virtuel à portée de main
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rapide :</strong> Résultats en quelques secondes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Facile :</strong> Intuitif dès le début
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualité :</strong> Résultats hyperréalistes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Aide :</strong> Assistance humaine si nécessaire
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Essayez Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29 € pour 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Directeur de la Photographie chez aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agent Immobilier chez Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, PDG d'Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, PDG de Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Plus de 100 témoignages
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Avant de vider virtuellement la pièce"}
                  altAfter={"Après avoir vidé virtuellement la pièce"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Furnish" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Furnish")}
                    >
                      <PurpleFurnishIcon />
                      Salon
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Bedroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bedroom")}
                    >
                      <BedIcon />
                      Chambre
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Bathroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bathroom")}
                    >
                      <BathroomIcon />
                      Salle de bain
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Terrace" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Terrace")}
                    >
                      <PurpleEnhanceIcon />
                      Terrasse
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 5 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Home staging virtuel en 1 clic.{" "}
          <span className="text-highlight">
            Arrêtez de dépenser beaucoup d'argent et d'heures pour adapter la
            présentation de vos biens.
          </span>{" "}
          Avec Pedra, vous pouvez faire vous-même les changements nécessaires en
          quelques secondes et obtenir le home staging virtuel qui{" "}
          <span className="text-highlight">vous aidera à vendre</span>.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h2 className="feature-title">
                  Créez un home staging virtuel en meublant automatiquement
                </h2>
                <p className="feature-text">
                  Choisissez simplement le type de bien et Pedra se chargera de
                  placer les meubles pour vous.
                </p>
              </div>
              <img src={HomeStagingPage1} alt="Créer un home staging virtuel" />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">
                  Videz virtuellement les pièces - en 1 clic !
                </h2>
                <p className="feature-text">
                  Utilisez l'IA de Pedra pour vider magiquement les pièces en un
                  seul clic et obtenir le home staging virtuel parfait. Parfois,
                  il suffit de retirer l'encombrement et les meubles des photos.
                </p>
              </div>
              <img
                src={HomeStagingPage2}
                alt="Outil de vidage de pièce - home staging virtuel"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangePaletteIcon />
                <h2 className="feature-title">
                  Sélectionnez des styles de home staging virtuel
                </h2>
                <p className="feature-text">
                  Choisissez parmi nos différents styles de home staging virtuel
                  : minimaliste, scandinave, bohème, etc. Choisissez le style
                  qui convient le mieux à votre client potentiel.
                </p>
              </div>
              <img
                src={HomeStagingPage3}
                alt="Sélectionner des styles de home staging virtuel"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h2 className="feature-title">
                  Changez les sols pour votre home staging virtuel
                </h2>
                <p className="feature-text">
                  Avec Pedra, vous pouvez rénover ce parquet démodé et le faire
                  paraître en parfait état. Ou pour ces terrasses avec un sol
                  dépassé, vous pouvez le rénover virtuellement.
                </p>
              </div>
              <img
                src={HomeStagingPage4}
                alt="Outil de changement de sol virtuel utilisant le home staging virtuel"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h2 className="feature-title">
                  Home staging virtuel avec des pièces 3D
                </h2>
                <p className="feature-text">
                  Utilisez la bibliothèque de meubles 3D de Pedra pour créer un
                  home staging virtuel à votre goût. Ajoutez des meubles,
                  faites-les pivoter, agrandissez-les et cliquez sur générer
                  l'image pour créer le home staging virtuel.
                </p>
              </div>
              <img
                src={HomeStagingPage5}
                alt="Outil de home staging virtuel utilisant l'IA - Capture d'écran du logiciel"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h2 className="feature-title">
                  Supprimez des objets avec l'IA pour votre home staging virtuel
                </h2>
                <p className="feature-text">
                  Supprimez des parties d'une image avant ou après le home
                  staging virtuel.
                </p>
              </div>
              <img
                src={HomeStagingPage6}
                alt="Suppression d'objets - Capture d'écran du logiciel"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">
                  Dépersonnalisez les propriétés facilement
                </h2>
                <p className="feature-text">
                  Notre logiciel de home staging virtuel vous permet de
                  dépersonnaliser les propriétés en quelques secondes. Cela
                  garantit que vos annonces attirent le maximum de personnes sur
                  les principaux portails immobiliers, améliorant vos chances de
                  vendre.
                </p>
              </div>
              <img
                src={HomeStagingPage7}
                alt="Exemple de dépersonnalisation avec le home staging virtuel"
              />
            </div>
            <div className="feature-container-expander"></div>
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeBlueSky />
                <h2 className="feature-title">
                  Transformez le ciel en un clic
                </h2>
                <p className="feature-text">
                  Avec nos outils de home staging virtuel, vous pouvez
                  transformer les ciels gris en ciels bleus radieux en quelques
                  secondes. Créez une atmosphère positive qui invite les
                  acheteurs à imaginer des jours ensoleillés dans leur future
                  maison. Éveillez des émotions et accélérez vos ventes avec des
                  ciels parfaits !
                </p>
              </div>
              <img
                src={RealEstatePhotographyEsPage6}
                alt="Transformation du ciel utilisant le home staging virtuel"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container">
          Lisez quelques-uns des témoignages sur notre outil de home staging
          virtuel ↓
        </h3>
      </div>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Directeur de la Photographie chez aProperties"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Directeur de la Photographie chez aProperties
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra fonctionne très bien. Je pense que le meilleur aspect de
            l'outil est la rapidité avec laquelle il génère les images.
          </q>
          <div className="testimonial-author-container-date">
            3 juillet 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Soma}
              alt="Francisco Somacarrera, Agent Immobilier chez Huspy"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Francisco Somacarrera
              </div>
              <div className="testimonial-author-container-second-title">
                Agent Immobilier chez Huspy
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            🌈 🙌🏾 Je suis revenu à{" "}
            <a
              className="article-link"
              href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7211297450987270144?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7211297450987270144%2C7212456394883555329%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287212456394883555329%2Curn%3Ali%3AugcPost%3A7211297450987270144%29"
              rel="nofollow"
            >
              faire de la magie avec Pedra
            </a>
            … Maintenant, il est temps d'attendre !
          </q>
          <div className="testimonial-author-container-date">
            1er juillet 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Conseillère Immobilière chez iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Conseillère Immobilière chez iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">Pedra est la découverte du siècle</q>
          <div className="testimonial-author-container-date">
            3 juillet 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DanieldelaMata}
              alt="Daniel de la Mata, PDG d'Actívox"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Daniel de la Mata
              </div>
              <div className="testimonial-author-container-second-title">
                PDG d'Actívox
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra nous est très utile. Nous sommes très satisfaits !!!!
            <div style={{ marginTop: "10px", display: "inline" }}>
              Ça devrait être plus cher.
            </div>
          </q>
          <div className="testimonial-author-container-date">28 mai 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Agent Immobilier chez iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Agent Immobilier chez iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Impressionnant.
            <div style={{ marginTop: "10px", display: "inline" }}>
              De plus, l'espace spécifique que je voulais vider semblait plus
              compliqué car il était profond, mais il a fait un excellent
              travail.
            </div>
          </q>
          <div className="testimonial-author-container-date">26 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Manager chez Lucas Fox"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Manager chez Lucas Fox
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Quelques maisons ont été vendues grâce à Pedra.
            <div style={{ marginTop: "10px", display: "inline" }}>
              J'ai placé des propositions sur l'aspect que pourrait avoir la
              terrasse meublée dans la photo de couverture et beaucoup de gens
              ont commencé à cliquer sur l'annonce
            </div>
          </q>
          <div className="testimonial-author-container-date">7 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MariaJoseGilart}
              alt="Maria José Gilart, PDG de Gilart Consultors"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Maria José Gilart
              </div>
              <div className="testimonial-author-container-second-title">
                PDG de Gilart Consultors
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Je vous félicite, car cela s'est beaucoup amélioré !!!
          </q>
          <div className="testimonial-author-container-date">2 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Agent immobilier
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Avec Pedra, j'ai réussi à{" "}
            <a
              className="article-link"
              href="https://www.instagram.com/p/C4A6kCmIeMy/"
              rel="nofollow"
            >
              vendre un bien de 500 000 €
            </a>{" "}
            en moins d'une journée. L'appartement que j'avais à vendre était un
            appartement À RÉNOVER et avec Pedra, j'ai obtenu des rendus qui ont
            permis de visualiser le potentiel des espaces après rénovation mais
            sans rénovation !
          </q>
          <div className="testimonial-author-container-date">2 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MacarenaRico}
              alt="Macarena Rico, Département des Ventes chez Inmobiliaria Rico"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Macarena Rico
              </div>
              <div className="testimonial-author-container-second-title">
                Département des Ventes chez Inmobiliaria Rico
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Je suis satisfaite de l'application Pedra.
          </q>
          <div className="testimonial-author-container-date">24 avril 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Conseillère Immobilière chez Monapart"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Conseillère Immobilière chez Monapart
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Je suis très satisfaite des résultats
            <div style={{ marginTop: "10px", display: "inline" }}>
              De plus, ils ont amélioré le produit en fonction des retours que
              j'ai partagés !
            </div>
          </q>
          <div className="testimonial-author-container-date">4 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DavidCanoyra}
              alt="David Canoyra, Inmobiliaria Encuentro, Boadilla del Monte"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                David Canoyra
              </div>
              <div className="testimonial-author-container-second-title">
                Inmobiliaria Encuentro, Boadilla del Monte
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">C'est un bon outil.</q>
          <div className="testimonial-author-container-date">6 mai 2024</div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Vos besoins en home staging virtuel couverts pour 29 € par mois.{" "}
          <br /> <span className="text-highlight"> Et sans engagement.</span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29 €</div>
              <div className="modal-product-plan-description-text-3">
                par mois
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclut</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#A37EE3" }}>
                400 générations
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>par mois</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Rénover et redécorer
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Vider les pièces
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Meubler
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Supprimer des objets
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Augmenter la résolution
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Changer les sols
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Améliorer la photographie
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Générer des vidéos virtuelles
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Plans d'étage illimités
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Partage de projets illimité
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Ajouter des filigranes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Support illimité
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Essayez Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Annulez quand vous voulez.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Achetez plus d'images à 0,20 € par image si vous en avez besoin.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">Questions et Réponses</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h2 className="question-title">
                Qu'est-ce que le home staging ?
              </h2>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Le home staging est une technique de marketing immobilier qui
                consiste à préparer un bien (maison, appartement, etc.) pour la
                vente ou la location dans le but de le rendre plus attrayant
                pour les acheteurs ou locataires potentiels. Cela se fait par
                une présentation et une décoration stratégiques de l'espace pour
                mettre en valeur ses caractéristiques les plus positives et
                minimiser ses défauts.
                <br />
                <br />
                Le processus de home staging implique le nettoyage, la
                dépersonnalisation et le désencombrement du bien pour permettre
                aux acheteurs ou locataires potentiels de visualiser facilement
                comment ce serait de vivre dans cet espace. Il peut également
                inclure le réarrangement des meubles, l'ajout d'éléments
                décoratifs et, dans certains cas, de petites rénovations ou
                réparations pour améliorer l'apparence générale du bien.
                <br />
                <br />
                L'objectif du home staging est de créer une première impression
                positive chez les visiteurs et d'augmenter les chances de vendre
                ou de louer le bien rapidement et à un prix favorable. C'est une
                stratégie de plus en plus populaire sur le marché immobilier en
                raison de sa capacité à améliorer l'attrait visuel et émotionnel
                d'un bien.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Que comprend le home staging virtuel ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Le home staging virtuel est une variante du home staging
                  traditionnel qui utilise des outils numériques et la
                  technologie pour présenter un bien de manière attrayante aux
                  acheteurs ou locataires potentiels. Au lieu de faire des
                  changements physiques au bien, comme réarranger les meubles ou
                  ajouter des éléments décoratifs, le home staging virtuel se
                  fait principalement de la manière suivante :
                </div>
                <h4>Édition d'images :</h4>Avec Pedra, vous pouvez éditer des
                images avec l'IA pour retoucher et améliorer les photographies
                des biens. Cela peut impliquer la suppression d'objets
                indésirables, l'ajustement de l'éclairage et de la couleur, et
                même l'ajout de meubles ou de décorations virtuels.
                <br />
                <br />
                <h4>Rendu de conception :</h4> Créez des représentations
                numériques de l'apparence que pourrait avoir le bien après avoir
                été décoré ou rénové. Cela peut aider les acheteurs à visualiser
                le potentiel de l'espace et inspirer des idées pour leur propre
                décoration.
                <br />
                <br />
                <h4>Économie de temps et d'argent :</h4>Le home staging virtuel
                est plus rapide et plus économique que le home staging
                traditionnel, car il ne nécessite pas de louer des meubles ou
                des accessoires physiques ni de faire des changements physiques
                au bien. Cela peut être particulièrement bénéfique pour les
                biens vides ou dans des situations où le temps est un facteur
                critique.
                <br />
                <br />
                <h4>Flexibilité et personnalisation :</h4>Avec le home staging
                virtuel, il est possible d'expérimenter différents styles de
                décoration et configurations d'espace rapidement et sans
                engagement. Cela permet d'adapter la présentation du bien en
                fonction du marché cible et des préférences des acheteurs ou
                locataires potentiels.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Conseils pour le home staging virtuel
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Connaissez votre public cible :</h4>Comprendre les besoins
                et les préférences de votre public cible vous aidera à créer un
                contenu de home staging virtuel qui les interpelle. Considérez
                qui sont les acheteurs ou locataires potentiels et adaptez votre
                approche en conséquence.
                <br />
                <br />
                <h4>Soyez honnête et transparent :</h4>Assurez-vous que le
                contenu de home staging virtuel reflète fidèlement l'apparence
                et les caractéristiques du bien. Évitez la sur-édition ou la
                manipulation excessive des images, car cela peut créer des
                attentes irréalistes.
                <br />
                <br />
                <h4>Angles multiples :</h4>Capturez le bien sous différents
                angles pour offrir une vue d'ensemble. Cela permet aux visiteurs
                d'avoir une meilleure idée de l'agencement de l'espace et des
                caractéristiques uniques du bien.
                <br />
                <br />
                <h4>Maintenez une cohérence stylistique :</h4>Utilisez un style
                de décoration cohérent dans toutes les images et visites
                virtuelles pour créer une expérience visuelle uniforme. Cela
                aide à maintenir l'intérêt des visiteurs et facilite la
                visualisation du bien dans son ensemble.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Prix du home staging virtuel</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Le prix de Pedra est de 29 € plus TVA par mois.
                <br />
                <br />
                Avec ce paiement, vous pouvez générer jusqu'à 100 images par
                mois.
                <br />
                <br />
                Il n'y a pas d'engagement, vous pouvez donc annuler lorsque vous
                n'en avez plus besoin.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ5(!FAQ5)}>
              <h3 className="question-title">
                Quelles sont les étapes du processus de Home Staging Virtuel ?
              </h3>
              {FAQ5 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ5 && (
              <div className="answer-container">
                Le processus de home staging est assez rapide et simple. Voici
                les étapes à suivre pour créer un home staging virtuel avec
                Pedra :
                <br />
                <br />
                1. <strong>Télécharger les photos du projet :</strong> Lors de
                la création d'un projet, vous pouvez télécharger une ou
                plusieurs images à éditer.
                <br />
                <br />
                2. <strong>Options de Home Staging Virtuel :</strong> Une fois
                que vous avez sélectionné les photos, dans le menu déroulant
                "Éditer avec l'IA", vous aurez toutes les options dont vous avez
                besoin, allant de vider les pièces à ajouter des meubles dans
                votre pièce, rénover les espaces, et plus encore.
                <br />
                <br />
                3. <strong>Télécharger votre projet :</strong> Une fois que vous
                avez effectué tous les changements et édité les images, il vous
                suffit de télécharger les images de votre Home Staging.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ6(!FAQ6)}>
              <h3 className="question-title">
                Peut-on réaliser un Home Staging Virtuel sur des propriétés
                vides ?
              </h3>
              {FAQ6 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ6 && (
              <div className="answer-container">
                <p>
                  Oui ! L'une des fonctions de notre outil de home staging
                  virtuel est de meubler l'espace. Vous pouvez utiliser l'option
                  "Meubler" pour meubler automatiquement l'espace avec l'IA, ou
                  si vous préférez, vous pouvez ajouter manuellement les meubles
                  que vous voulez avec l'option "Ajouter un objet".
                </p>
                <p>
                  Avec l'une ou l'autre de ces options, notre outil de Home
                  Staging Virtuel vous aide à visualiser le potentiel de vos
                  espaces, facilitant la prise de décision et améliorant la
                  présentation de la propriété.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStageVirtuelFr;
