// RenderEs.js
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeCleanerIcon from "./Icons/OrangeCleanerIcon.jsx";
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import OrangeBlueSky from "./Icons/OrangeBlueSky.jsx";
import CurtainEffect from "./CurtainEffect.js";
import BathroomIcon from "./Icons/hero-icons/BathroomIcon.jsx";
import BedIcon from "./Icons/hero-icons/BedIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";

//Images
import HomeStagingPage1 from "./Images/HomeStagingPage1.png";
import HomeStagingPage2 from "./Images/HomeStagingPage2.png";
import HomeStagingPage3 from "./Images/HomeStagingPage3.png";
import HomeStagingPage4 from "./Images/HomeStagingPage4.png";
import HomeStagingPage5 from "./Images/HomeStagingPage5.png";
import HomeStagingPage6 from "./Images/HomeStagingPage6.png";
import HomeStagingPage7 from "./Images/HomeStagingPage7.png";
import RealEstatePhotographyEsPage6 from "./Images/RealEstatePhotographyEsPage6.jpeg";
import Bouchra from "./Images/Bouchra image.png";
import DavidCanoyra from "./Images/David Canoyra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import MacarenaRico from "./Images/Macarena Rico image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeBedroomStaging from "./Images/curtain-effect-hero/BeforeBedroomStaging.png";
import AfterBedroomStaging from "./Images/curtain-effect-hero/AfterBedroomStaging.png";
import BeforeTerraceStaging from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterTerraceStaging from "./Images/curtain-effect-hero/AfterTerraceStaging.png";
import BeforeBathroomStaging from "./Images/curtain-effect-hero/BeforeBathroomStaging.png";
import AfterBathroomStaging from "./Images/curtain-effect-hero/AfterBathroomStaging.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function HomeStagingEs() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);

  const [example, setExample] = useState({
    name: "Furnish",
    beforeImage: BeforeFurnish,
    afterImage: AfterFurnish,
  });

  const handleExample = (example) => {
    console.log(example);
    if (example === "Furnish") {
      setExample({
        name: "Furnish",
        beforeImage: BeforeFurnish,
        afterImage: AfterFurnish,
      });
      console.log("Furnish");
    } else if (example === "Bedroom") {
      setExample({
        name: "Bedroom",
        beforeImage: BeforeBedroomStaging,
        afterImage: AfterBedroomStaging,
      });
    } else if (example === "Terrace") {
      setExample({
        name: "Terrace",
        beforeImage: BeforeTerraceStaging,
        afterImage: AfterTerraceStaging,
      });
    } else {
      setExample({
        name: "Bathroom",
        beforeImage: BeforeBathroomStaging,
        afterImage: AfterBathroomStaging,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "¿Qué es home staging virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El home staging virtual es una técnica de marketing inmobiliario que utiliza herramientas digitales para preparar y presentar una propiedad de manera atractiva a posibles compradores o inquilinos. A diferencia del home staging tradicional, que implica cambios físicos en la propiedad, el home staging virtual se realiza mediante la edición de imágenes y renderizados digitales para mejorar la apariencia de los espacios.",
        },
      },
      {
        "@type": "Question",
        name: "¿En qué consiste el home staging virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El home staging virtual consiste en editar imágenes de la propiedad usando herramientas digitales. Esto incluye: \n\n- Edición de imágenes: Retoque y mejora de fotos mediante IA, eliminando objetos no deseados, ajustando la iluminación, y añadiendo muebles o decoración virtuales. \n\n- Renderizado de diseño: Creación de representaciones digitales que muestran cómo podría lucir la propiedad decorada o renovada. \n\n- Ahorro de tiempo y dinero: Es más rápido y económico que el home staging tradicional, ya que no requiere cambios físicos en la propiedad.",
        },
      },
      {
        "@type": "Question",
        name: "¿Qué pasos incluye el proceso de Home Staging Virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El proceso de home staging virtual incluye los siguientes pasos: \n\n1. Subir las fotos del proyecto. \n\n2. Seleccionar las opciones de edición de home staging virtual, como vaciar habitaciones amuebladas o añadir muebles virtuales. \n\n3. Descargar el proyecto una vez finalizados los cambios.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cuánto tiempo lleva completar un proyecto de Home Staging Virtual?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El tiempo de completar un proyecto de home staging virtual depende de la cantidad de imágenes y del tiempo que se tarde en editarlas. Sin embargo, el tiempo de subir archivos y descargar proyectos es rápido, y los resultados se obtienen al instante.",
        },
      },
      {
        "@type": "Question",
        name: "¿Se pueden realizar Home Staging Virtual en propiedades vacías?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "¡Sí! El home staging virtual es ideal para propiedades vacías. Puedes amueblarlas con IA o de manera manual, añadiendo los muebles que desees, lo que ayuda a visualizar el potencial de los espacios y mejora la presentación de la propiedad.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home staging virtual</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2
                  style={{
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  El mejor home staging virtual en tus manos
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rápido:</strong> Resultados en segundos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Fácil:</strong> Intuitivo desde el principio
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Calidad:</strong> Resultados hiperrealistas
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Ayuda:</strong> Asistencia humana si es necesario
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Prueba Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ por 100 imágenes
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director of Photography at aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO at Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO at Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ testimonios
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Before virtually emptying the room"}
                  altAfter={"After virtually emptying the room"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Furnish" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Furnish")}
                    >
                      <PurpleFurnishIcon />
                      Salón
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Bedroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bedroom")}
                    >
                      <BedIcon />
                      Dormitorio
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Bathroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bathroom")}
                    >
                      <BathroomIcon />
                      Baño
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Terrace" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Terrace")}
                    >
                      <PurpleEnhanceIcon />
                      Terraza
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MÁS DE 5,000 PROFESIONALES INMOBILIARIOS YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Home stagings virtuales en 1 click.{" "}
          <span className="text-highlight">
            Deja de gastar mucho dinero y horas para adecuar la presentación de
            tus inmuebles.
          </span>{" "}
          Con Pedra podrás hacer los cambios necesarios tu mismo en segundos y
          conseguir el home staging virtual que{" "}
          <span className="text-highlight">te ayudará a vender</span>.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h2 className="feature-title">
                  Haz un home staging virtual amueblando de manera automática
                </h2>
                <p className="feature-text">
                  Tan sólo escoge el tipo de propiedad y Pedra se encargará de
                  colocar los muebles por ti.
                </p>
              </div>
              <img src={HomeStagingPage1} alt="Crear home staging virtual" />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">
                  Vacía habitaciones virtualmente – en 1 click!
                </h2>
                <p className="feature-text">
                  Usa la IA de Pedra para vaciar habitaciones de manera mágica
                  en 1 solo click y quedarte con el home staging virtual
                  perfecto. A veces sólo hace falta eliminar los tratos y
                  muebles de las fotografías.
                </p>
              </div>
              <img
                src={HomeStagingPage2}
                alt="Herramienta de vaciar habitación – home staging virtual"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangePaletteIcon />
                <h2 className="feature-title">
                  Selecciona estilos de home staging virtual
                </h2>
                <p className="feature-text">
                  Elige uno de nuestros diversos estilos de home staging
                  virtual: minimalista, escandinavo, bohemio, etc. Escoge el
                  estilo que mejor se adapte a tu cliente potencial.
                </p>
              </div>
              <img
                src={HomeStagingPage3}
                alt="Seleccionar estilos de home staging virtual"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h2 className="feature-title">
                  Cambia suelos para tu home staging virtual
                </h2>
                <p className="feature-text">
                  Con Pedra podrás renovar ese parquet anticuado y que quede un
                  parquet en perfecto estado. O para aquellas terrazas con un
                  suelo anticuado, lo podrás renvoar virtualmente.
                </p>
              </div>
              <img
                src={HomeStagingPage4}
                alt="Herramienta de cambio de virtualmente usando home staging virtual"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h2 className="feature-title">
                  Home staging virtual con piezas 3D
                </h2>
                <p className="feature-text">
                  Usa la librería de muebles 3D de Pedra para hacer una home
                  staging virtual a tu gusto. Añade los muebles, rótalos, hazlos
                  más grande y dale a generar imagen para crear el home staging
                  virtual.
                </p>
              </div>
              <img
                src={HomeStagingPage5}
                alt="Herramienta de home staging virtual usando con IA – Captura de pantalla del software"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h2 className="feature-title">
                  Elimina objetos con la IA para tu home staging virtual
                </h2>
                <p className="feature-text">
                  Elimina partes de una imagen antes o después del home staging
                  virtual.
                </p>
              </div>
              <img
                src={HomeStagingPage6}
                alt="Eliminar Objetos - Captura de pantalla del software"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">
                  Despersonaliza propiedades con facilidad
                </h2>
                <p className="feature-text">
                  Nuestro software de home staging virtual te permite
                  despersonalizar propiedades en cuestión de segundos. Esto
                  asegura que tus anuncios atraigan al máximo número de personas
                  en los principales portales inmobiliarios, mejorando tus
                  posibilidades de venta.
                </p>
              </div>
              <img
                src={HomeStagingPage7}
                alt="Ejemplo de despersonalización con home staging virtual"
              />
            </div>
            <div className="feature-container-expander"></div>
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeBlueSky />
                <h2 className="feature-title">
                  Transforma el cielo con un clic
                </h2>
                <p className="feature-text">
                  Con nuestras herramientas de home staging virtual, puedes
                  cambiar cielos grises por azules radiantes en segundos. Crea
                  una atmósfera positiva que invite a los compradores a imaginar
                  días soleados en su futura casa. ¡Despierta emociones y
                  acelera tus ventas con cielos perfectos!
                </p>
              </div>
              <img
                src={RealEstatePhotographyEsPage6}
                alt="Transformación de cielo mediante home staging virtual"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container">
          Lee algunos de los testimonios de nuestra herramienta de home staging
          virtual ↓
        </h3>
      </div>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Director de Fotografía en aProperties"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Director de Fotografía en aProperties
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra está yendo estupendamente. Creo que lo mejor que tiene la
            herramienta es la rapidez con que genera las imágenes.
          </q>
          <div className="testimonial-author-container-date">3 July, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Soma}
              alt="Francisco Somacarrera, Agente inmobiliario en Huspy"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Francisco Somacarrera
              </div>
              <div className="testimonial-author-container-second-title">
                Agente inmobiliario en Huspy
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            🌈 🙌🏾 He vuelto{" "}
            <a
              className="article-link"
              href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7211297450987270144?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7211297450987270144%2C7212456394883555329%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287212456394883555329%2Curn%3Ali%3AugcPost%3A7211297450987270144%29"
              rel="nofollow"
            >
              a hacer magia con Pedra
            </a>
            … Ahora toca espera!
          </q>
          <div className="testimonial-author-container-date">
            1 de Julio, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Asesor inmobiliario en iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Asesor inmobiliario en iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra es el descubrimiento del siglo
          </q>
          <div className="testimonial-author-container-date">3 July, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DanieldelaMata}
              alt="Daniel de la Mata, CEO en Actívox"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Daniel de la Mata
              </div>
              <div className="testimonial-author-container-second-title">
                CEO en Actívox
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra nos está siendo útil. Estamos muy contentos!!!!
            <div style={{ marginTop: "10px", display: "inline" }}>
              Debería de ser más caro.
            </div>
          </q>
          <div className="testimonial-author-container-date">28 May, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Agente Inmobiliario en iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Agente Inmobiliario en iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Impresionante.
            <div style={{ marginTop: "10px", display: "inline" }}>
              Además que el espacio en concreto que quería vaciar me parecía más
              complicado por ser profundo, pero lo ha hecho genial.
            </div>
          </q>
          <div className="testimonial-author-container-date">
            26 Junio, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Manager en Lucas Fox"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Manager en Lucas Fox
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Un par de casas se han vendido gracias a Pedra.
            <div style={{ marginTop: "10px", display: "inline" }}>
              Coloqué en foto de portada propuestas de como podría quedar la
              terraza amueblada y muchísima gente empezo a hacer click en el
              anuncio
            </div>
          </q>
          <div className="testimonial-author-container-date">7 Junio, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MariaJoseGilart}
              alt="Maria José Gilart, CEO en Gilart Consultors"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Maria José Gilart
              </div>
              <div className="testimonial-author-container-second-title">
                CEO en Gilart Consultors
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Os felicito, porque ha mejorado mucho¡¡¡
          </q>
          <div className="testimonial-author-container-date">2 Junio, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Agente inmobiliaro
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Con Pedra conseguí{" "}
            <a
              className="article-link"
              href="https://www.instagram.com/p/C4A6kCmIeMy/"
              rel="nofollow"
            >
              vender un inmueble de €500k
            </a>{" "}
            en menos de un día. El piso que tenía a la venta era un piso A
            REFORMAR y con Pedra conseguí unos renders que permitieron
            visualizar el potencial de las espacios después de reforma pero sin
            reforma!
          </q>
          <div className="testimonial-author-container-date">2 Junio, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MacarenaRico}
              alt="Macarena Rico, Dpt. de ventas en Inmobiliaria Rico"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Macarena Rico
              </div>
              <div className="testimonial-author-container-second-title">
                Dpt. de ventas en Inmobiliaria Rico
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Estoy contenta con la aplicación de Pedra.
          </q>
          <div className="testimonial-author-container-date">
            24 Abril, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Asesora inmobiliaria en Monapart"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Asesora inmobiliaria en Monapart
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Estoy muy satisfecha con los resultados
            <div style={{ marginTop: "10px", display: "inline" }}>
              Además, han ido mejorando el producto según el feedback que les he
              compartido!
            </div>
          </q>
          <div className="testimonial-author-container-date">
            4 de Junio, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DavidCanoyra}
              alt="David Canoyra, Inmobiliaria Encuentro, Boadilla del Monte"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                David Canoyra
              </div>
              <div className="testimonial-author-container-second-title">
                Inmobiliaria Encuentro, Boadilla del Monte
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">Es una buena herramienta.</q>
          <div className="testimonial-author-container-date">
            6 de Mayo, 2024
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Los home staging virtuales qué necesitas por 29€ al mes. <br />{" "}
          <span className="text-highlight"> Y sin permanencia.</span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                por mes
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Incluye</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#A37EE3" }}>
                100 generaciones de imagen
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>por mes</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renovar y redecorar
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Vaciar habitaciones
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Amueblar
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Quitar objetos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Aumentar resolución
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Cambiar suelos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Mejorar fotografía
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Generar videos virtuales
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Planos ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartir proyectos ilimitado
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Añadir marcas de agua
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Soporte ilimitado
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Prueba Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancela cuando quieras.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Compra más imágenes a 0.20€ por imagen si las necesitas.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">Preguntas y respuestas</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h2 className="question-title">¿Qué es home staging?</h2>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                El home staging es una técnica de marketing inmobiliario que
                consiste en preparar una propiedad (casa, apartamento, etc.)
                para la venta o alquiler con el objetivo de hacerla más
                atractiva para potenciales compradores o inquilinos. Esto se
                logra mediante la presentación y decoración estratégica del
                espacio para resaltar sus características más positivas y
                minimizar sus defectos.
                <br />
                <br />
                El proceso de home staging implica la limpieza,
                despersonalización y despeje de la propiedad para permitir que
                los posibles compradores o inquilinos visualicen fácilmente cómo
                podría ser vivir en ese espacio. También puede incluir la
                reorganización del mobiliario, la adición de elementos
                decorativos y, en algunos casos, pequeñas renovaciones o
                reparaciones para mejorar la apariencia general de la propiedad.
                <br />
                <br />
                El objetivo del home staging es generar una primera impresión
                positiva en los visitantes y aumentar las probabilidades de
                vender o alquilar la propiedad rápidamente y a un precio
                favorable. Es una estrategia cada vez más popular en el mercado
                inmobiliario debido a su capacidad para mejorar el atractivo
                visual y emocional de una propiedad.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿En qué consiste el home staging virtual?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  El home staging virtual es una variante del home staging
                  tradicional que utiliza herramientas digitales y tecnología
                  para presentar una propiedad de manera atractiva a los
                  posibles compradores o inquilinos. En lugar de realizar
                  cambios físicos en la propiedad, como reorganizar el
                  mobiliario o añadir elementos decorativos, el home staging
                  virtual se lleva a cabo principalmente mediante las siguientes
                  formas:
                </div>
                <h4>Edición de imágenes:</h4>Con Pedra podrás editar las
                imágenes con IA para retocar y mejorar las fotografías de la
                propiedad. Esto puede implicar la eliminación de objetos no
                deseados, ajustes en la iluminación y el color, e incluso la
                adición de muebles virtuales o decoración.
                <br />
                <br />
                <h4>Renderizado de diseño:</h4> Crea representaciones digitales
                de cómo podría lucir la propiedad después de ser decorada o
                renovada. Esto puede ayudar a los compradores a visualizar el
                potencial del espacio y a inspirar ideas para su propia
                decoración.
                <br />
                <br />
                <h4>Ahorro de tiempo y dinero:</h4>El home staging virtual es
                más rápido y económico que el home staging tradicional, ya que
                no requiere la contratación de muebles o accesorios físicos ni
                la realización de cambios físicos en la propiedad. Esto puede
                ser especialmente beneficioso en propiedades vacías o en
                situaciones en las que el tiempo es un factor crítico.
                <br />
                <br />
                <h4>Flexibilidad y personalización:</h4>Con el home staging
                virtual, es posible experimentar con diferentes estilos de
                decoración y configuraciones de espacio de manera rápida y sin
                compromisos. Esto permite adaptar la presentación de la
                propiedad según el mercado objetivo y las preferencias de los
                posibles compradores o inquilinos.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Consejos de home staging virtual
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Conoce a tu público objetivo:</h4>Entender las necesidades y
                preferencias de tu público objetivo te ayudará a crear contenido
                de home staging virtual que resuene con ellos. Considera quiénes
                son los posibles compradores o inquilinos y adapta tu enfoque en
                consecuencia.
                <br />
                <br />
                <h4>Sé honesto y transparente:</h4>Asegúrate de que el contenido
                de home staging virtual refleje con precisión la apariencia y
                las características de la propiedad. Evita la sobreedición o la
                manipulación excesiva de imágenes, ya que esto puede generar
                expectativas poco realistas.
                <br />
                <br />
                <h4>Múltiples ángulos:</h4>Captura la propiedad desde varios
                ángulos para proporcionar una visión completa. Esto permite a
                los espectadores tener una mejor idea de la distribución del
                espacio y las características únicas de la propiedad.
                <br />
                <br />
                <h4>Mantén la consistencia estilística:</h4>Utiliza un estilo de
                decoración coherente en todas las imágenes y recorridos
                virtuales para crear una experiencia visual uniforme. Esto ayuda
                a mantener el interés de los espectadores y facilita la
                visualización de la propiedad como un todo.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Precio home staging virtual</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                El precio de Pedra es de 29€ más IVA al mes.
                <br />
                <br />
                Por ese precio podrás generar hasta 100 imágenes al mes.
                <br />
                <br />
                No tiene permanencia, así que puedes darte de baja cuando ya no
                lo necesites.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ5(!FAQ5)}>
              <h3 className="question-title">
                ¿Qué pasos incluye el proceso de Home Staging Virtual?
              </h3>
              {FAQ5 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ5 && (
              <div className="answer-container">
                El proceso de home staging es bastante rápido y sencillo, a
                continuación os dejamos los pasos a seguir para crear con Pedra
                un home staging virtual:
                <br />
                <br />
                1. <strong>Subir las fotos del proyecto:</strong> Al crear un
                proyecto podrás subir una o más imágenes para editarlas.
                <br />
                <br />
                2. <strong>Opciones del Home staging virtual:</strong> Una vez
                selecciones las fotos, dentro del desplegable de "Editar con IA"
                tedndrás todas las opciones que necesitas, desde vaciar
                habitaciones, hasta añadir muebles a tu habitación, renovar las
                estancias y más.
                <br />
                <br />
                3. <strong>Descargar tu proyecto:</strong> Una vez realizado
                todos los cambios y editadas las imágenes solo tendrás que
                descargar las imágenes de tu Home Staging
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ6(!FAQ6)}>
              <h3 className="question-title">
                ¿Se pueden realizar Home Staging Virtual en propiedades vacías?
              </h3>
              {FAQ6 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ6 && (
              <div className="answer-container">
                <p>
                  ¡Sí! Una de las funciones de nuestra herramienta de home
                  staging virtual es amueblar la estancia. Puedes utilizar la
                  opción de "Amueblar" para amueblar el espacio de manera
                  automática con IA, o si lo prefieres de manera manual podrás
                  añadir los muebles que quieras con la opción de "Añadir
                  objeto".
                </p>
                <p>
                  Con cualquiera de estas opciones, nuestra herramienta de Home
                  Staging Virtual te ayuda a visualizar el potencial de tus
                  espacios, facilitando la toma de decisiones y mejorando la
                  presentación de la propiedad.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStagingEs;
