import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageFrame2.jpg";
import HowToHomeStageFrame3Es from "../Images/HowToHomeStageFrame3.jpg";
import Example1Before from "../Images/Example1BeforeHomeStaging.jpg";
import Example1After from "../Images/Example1AfterHomeStaging.jpg";
import Example2Before from "../Images/Example2BeforeHomeStaging.jpg";
import Example2After from "../Images/Example2AfterHomeStaging.jpg";
import Example3Before from "../Images/Example3BeforeHomeStaging.jpg";
import Example3After from "../Images/Example3AfterHomeStaging.jpg";
import Example4Before from "../Images/curtain-effect-hero/BeforeBedroomStaging.png";
import Example4After from "../Images/curtain-effect-hero/AfterBedroomStaging.png";
import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo crear un home staging virtual
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre cómo crear un home staging virtual
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Cómo puedo crear un home staging virtual?
            </h2>
            <p className="article-text">
              Con Pedra puedes crear un home staging virtual en pocos clics.
              <p>
                Para comenzar, accede al menú de{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                selecciona <span style={{ fontWeight: "500" }}>"Amueblar"</span>
                . Selecciona el tipo de habitación y en unos 2 o 3 minutos
                tendrás la imagen de tu home staging virtual listo para
                descargar.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Cómo crear un home staging virtual – Ejemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              ¿Cómo crear un home staging virtual para obra nueva?
            </h2>
            <p className="article-text">
              Si tienes una propiedad de obra nueva sin muebles, puedes utilizar
              el home staging virtual de Pedra para mostrarla más atractiva de
              cara a los potenciales compradores y venderla más rápido.
            </p>
            <p className="article-text">
              Para conseguir tu{" "}
              <Link className="article-link" to="/es/home-staging-virtual">
                {" "}
                home staging virtual
              </Link>
              , sigue estos sencillos pasos:
            </p>
            <ol>
              <li>
                Sube las imágenes de las cuales te gustaría hacer el home
                staging virtual
              </li>
              <li>Selecciona una de ellas para empezar a editarla</li>
              <li>
                Haz click en span{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span>y
                luego haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Amueblar"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menú de cómo crear un home staging virtual"
            />
            <p className="article-text">
              Dentro de <span style={{ fontWeight: "500" }}>"Amueblar"</span>{" "}
              tendrás que seguir estos pasos:
            </p>
            <ol>
              <li>
                Selecciona la Dentro de{" "}
                <span style={{ fontWeight: "500" }}>
                  posición de los muebles
                </span>
                . Si seleccionas{" "}
                <span style={{ fontWeight: "500" }}>"Auto"</span> Pedra decidirá
                donde colocar los muebles y el espacio que ocupan. Si
                seleccionar <span style={{ fontWeight: "500" }}>"Dibujar"</span>{" "}
                podrás pintar sobre la imagen para indicar donde aparecen los
                muebles.
              </li>
              <li>
                Selecciona el seleccionar{" "}
                <span style={{ fontWeight: "500" }}>tipo de habitación</span>.
                Si escoges dormitorio, Pedra colocará muebles de dormitorio:
                cama, mesita de noche, etc. Si escoges salón, Pedra colocará
                sofás, televisor, alfombra, etc.
              </li>
              <li>
                Selecciona el <span style={{ fontWeight: "500" }}>estilo</span>.
                El estilo ayudará a determinar el tipo de muebles que se añaden
                al espacio.
              </li>
              <li>
                Escoge el{" "}
                <span style={{ fontWeight: "500" }}>
                  número de imágenes a generar
                </span>
                . Recomendamos que escojas 2 o 3 imágenes a generar para que
                puedas quedarte con la que más te guste.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Problemas típicos al crear un home staging virtual:
                <ul>
                  <li>
                    Si la fotografía tiene muy mala calidad puede ser que los
                    resultados no sean buenos
                  </li>
                  <li>
                    Si no se ve el techo, los muebles pueden aparecer
                    desproporcionados
                  </li>
                  <li>
                    La opción "Auto" puede ser imprecisa a veces. Si el "Auto"
                    no te funciona bien, utiliza "Dibujar"
                  </li>
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              ¿Cómo crear un home staging virtual para segunda mano?
            </h2>
            <p className="article-text">
              Si tienes una propiedad de segunda mano, ya sea con muebles o sin
              ellos, te recomendamos que uses la opción de{" "}
              <span style={{ fontWeight: "500" }}>"Renovación"</span>.
            </p>
            <p className="article-text">
              Para conseguir tu home staging virtual de una propiedad de segunda
              mano, sigue estos sencillos pasos:
            </p>
            <ol>
              <li>
                Sube las imágenes de las cuales te gustaría hacer el home
                staging virtual
              </li>
              <li>Selecciona una de ellas para empezar a editarla</li>
              <li>
                Haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                luego haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Renovación"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame3Es}
              alt="Menú de cómo crear un home staging virtual para segunda mano"
            />
            <p className="article-text">
              Dentro de <span style={{ fontWeight: "500" }}>"Renovación"</span>{" "}
              tendrás que seguir estos pasos:
            </p>
            <ol>
              <li>
                Selecciona el estilo. Recomendamos "Escandinavo" o "Minimalista"
                para resultados más neutros. Si lo prefieres,{" "}
                <Link
                  className="article-link"
                  to="https://www.youtube.com/watch?v=xMm8hKmSMSA"
                >
                  tienes la opción de subir una imagen del estilo que quieras y
                  realizar una renovación en base a ese estilo.
                </Link>
              </li>
              <li>
                Ajusta los detalles:
                <ul>
                  <li>
                    <span style={{ fontWeight: "500" }}>
                      Preservar ventanas:
                    </span>{" "}
                    elige si preservar las vistas de las ventanas o no
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Amueblar:</span> escoge
                    si quieres que se amueble el espacio. Como estamos creando
                    un home staging virtual seleccionaremos que sí. Esto hará
                    que se añadan muebles a cada espacio. En caso de que la
                    vivienda esté amueblada, pues se cambiará la distribución.
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Tipo de espacio:</span>{" "}
                    escoge En este caso dejémoslo en "Auto". Al dejar en "Auto",
                    Pedra interpretará qué tipo de espacio es y lo amueblará
                    acordemente. Si quisiéramos que nos amueblara una fotografía
                    con muebles de salón, deberemos de seleccionar salón
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Creatividad:</span> la
                    creatividad influenciará el nivel de cambios en la
                    arquitectura. Recomendamos que la dejemos en baja. Así
                    evitaremos que nos cambie la arquitectura del espacio
                    agresivamente.
                  </li>
                </ul>
              </li>
            </ol>
            <br />
            <h2 className="article-subtitleh2">
              ¿Por qué debería crear un home staging virtual?
            </h2>
            <p className="article-text">
              La primera vez que un potencial comprador ve una propiedad a la
              venta es por internet. Por esa razón, es sumamente crítico que lo
              primero que vea despierte sensaciones positivas e interés.
            </p>
            <p className="article-text">
              El{" "}
              <Link className="article-link" to="/es/home-staging-virtual">
                home staging virtual
              </Link>{" "}
              ayuda a generar buenas sensaciones. Con un buen home staging
              virtual podrás dar vida a espacios vacíos o mostrar la mejor
              versión de un piso que necesita una reforma. Es un aliado para
              vender propiedades rápido.
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Ejemplos de home staging virtual
            </h2>
            <p className="article-text">
              Aquí te dejo varios ejemplos de home stagings virtuales creados
              con Pedra:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <br />
            {/* 
            <h2 className="article-subtitleh2">
              Video de cómo crear un home staging virtual
            </h2>
            
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/PLACEHOLDER_VIDEO_ID"
                  title="Tutorial: Cómo crear un home staging virtual"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
            */}
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingEs;
