import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1En from "../Images/HowToHomeStageFrame1Es.jpg";
import HowToHomeStageFrame2En from "../Images/HowToHomeStageFrame2.jpg";
import HowToHomeStageFrame3En from "../Images/HowToHomeStageFrame3.jpg";
import Example1Before from "../Images/Example1BeforeHomeStaging.jpg";
import Example1After from "../Images/Example1AfterHomeStaging.jpg";
import Example2Before from "../Images/Example2BeforeHomeStaging.jpg";
import Example2After from "../Images/Example2AfterHomeStaging.jpg";
import Example3Before from "../Images/Example3BeforeHomeStaging.jpg";
import Example3After from "../Images/Example3AfterHomeStaging.jpg";
import Example4Before from "../Images/curtain-effect-hero/BeforeBedroomStaging.png";
import Example4After from "../Images/curtain-effect-hero/AfterBedroomStaging.png";
import CurtainEffect from "../CurtainEffect";

function HowToVirtualStaging() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to create a virtual home staging
          </h1>
          <h2 className="title-secondary gray">
            Tutorial on how to create virtual home staging
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder of Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              How can I create a virtual home staging?
            </h2>
            <p className="article-text">
              With Pedra, you can create virtual home stagings in just a few
              clicks.
              <p>
                To start, access the{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> menu
                and select <span style={{ fontWeight: "500" }}>"Furnish"</span>.
                Select the room type and in about 2 or 3 minutes, you'll have
                your virtual home staging image ready to download.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1En}
              alt="How to create virtual home staging - Examples"
            />
            <br />
            <h2 className="article-subtitleh2">
              How to create a virtual home staging for a new construction?
            </h2>
            <p className="article-text">
              If you have a new construction property without furniture, you can
              use Pedra's virtual home staging to make it more attractive to
              potential buyers and sell it faster.
            </p>
            <p className="article-text">
              To get your{" "}
              <Link className="article-link" to="/home-staging-virtual">
                {" "}
                virtual home staging
              </Link>
              , follow these simple steps:
            </p>
            <ol>
              <li>
                Upload the images you'd like to create virtual home staging for
              </li>
              <li>Select one of them to start editing</li>
              <li>
                Click on{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> and
                then click on{" "}
                <span style={{ fontWeight: "500" }}>"Furnish"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2En}
              alt="Menu for how to create virtual home staging"
            />
            <p className="article-text">
              Within <span style={{ fontWeight: "500" }}>"Furnish"</span> you'll
              need to follow these steps:
            </p>
            <ol>
              <li>
                Select the{" "}
                <span style={{ fontWeight: "500" }}>furniture position</span>.
                If you select <span style={{ fontWeight: "500" }}>"Auto"</span>,
                Pedra will decide where to place the furniture and how much
                space they occupy. If you select{" "}
                <span style={{ fontWeight: "500" }}>"Draw"</span> you can paint
                on the image to indicate where the furniture appears.
              </li>
              <li>
                Select the <span style={{ fontWeight: "500" }}>room type</span>.
                If you choose bedroom, Pedra will place bedroom furniture: bed,
                nightstand, etc. If you choose living room, Pedra will place
                sofas, TV, rug, etc.
              </li>
              <li>
                Select the <span style={{ fontWeight: "500" }}>style</span>. The
                style will help determine the type of furniture added to the
                space.
              </li>
              <li>
                Choose the{" "}
                <span style={{ fontWeight: "500" }}>
                  number of images to generate
                </span>
                . We recommend choosing 2 or 3 images to generate so you can
                keep the one you like best.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Common issues when creating virtual home staging:
                <ul>
                  <li>
                    If the photograph is of very poor quality, the results may
                    not be good
                  </li>
                  <li>
                    If the ceiling is not visible, the furniture may appear
                    disproportionate
                  </li>
                  <li>
                    The "Auto" option can be imprecise at times. If "Auto"
                    doesn't work well for you, use "Draw"
                  </li>
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              How to create virtual home staging for second-hand properties?
            </h2>
            <p className="article-text">
              If you have a second-hand property, whether furnished or
              unfurnished, we recommend using the{" "}
              <span style={{ fontWeight: "500" }}>"Renovation"</span> option.
            </p>
            <p className="article-text">
              To get your virtual home staging for a second-hand property,
              follow these simple steps:
            </p>
            <ol>
              <li>
                Upload the images you'd like to create virtual home staging for
              </li>
              <li>Select one of them to start editing</li>
              <li>
                Click on{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> and
                then click on{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame3En}
              alt="Menu for how to create virtual home staging for second-hand properties"
            />
            <p className="article-text">
              Within <span style={{ fontWeight: "500" }}>"Renovation"</span>{" "}
              you'll need to follow these steps:
            </p>
            <ol>
              <li>
                Select the style. We recommend "Scandinavian" or "Minimalist"
                for more neutral results. If you prefer, you have the option to
                upload an image of the style you want and perform a renovation
                based on that style.
              </li>
              <li>
                Adjust the details:
                <ul>
                  <li>
                    <span style={{ fontWeight: "500" }}>Preserve windows:</span>{" "}
                    choose whether to preserve the views from the windows or not
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Furnish:</span> choose
                    if you want the space to be furnished. As we're creating
                    virtual home staging, we'll select yes. This will add
                    furniture to each space. If the property is furnished, the
                    layout will be changed.
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Space type:</span>{" "}
                    choose. In this case, let's leave it on "Auto". By leaving
                    it on "Auto", Pedra will interpret what type of space it is
                    and furnish it accordingly. If we wanted it to furnish a
                    photo with living room furniture, we should select living
                    room
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Creativity:</span>{" "}
                    creativity will influence the level of changes in the
                    architecture. We recommend leaving it low. This way we'll
                    avoid aggressively changing the architecture of the space.
                  </li>
                </ul>
              </li>
            </ol>
            <br />
            <h2 className="article-subtitleh2">
              Why should I create virtual home staging?
            </h2>
            <p className="article-text">
              The first time a potential buyer sees a property for sale is on
              the internet. For that reason, it's extremely critical that the
              first thing they see evokes positive feelings and interest.
            </p>
            <p className="article-text">
              <Link className="article-link" to="/home-staging-virtual">
                Virtual home staging
              </Link>{" "}
              helps generate good feelings. With good virtual home staging, you
              can bring empty spaces to life or show the best version of a
              property that needs renovation. It's an ally for selling
              properties quickly.
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Examples of virtual home staging
            </h2>
            <p className="article-text">
              Here are several examples of virtual home stagings created with
              Pedra:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStaging;
